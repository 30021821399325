<template>
  <div>
    <v-layout justify-center wrap>
      <v-flex sm12 md12>
        <v-layout wrap>
          <v-flex xs12 style="color: #000; font-size: 20px; font-family: poppinsbold" pt-4 pb-5
            >Contact Details
          </v-flex>
          <v-flex xs12 v-if="contact.name">
            <v-text-field
              outlined
              v-model="contact.name"
              label="Name"
              dense
              class="textfd"
              readonly
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 v-if="contact.email">
            <v-text-field
              readonly
              outlined
              label="Email"
              dense
              v-model="contact.email"
              class="textfd"
            ></v-text-field>
          </v-flex>
           <v-flex xs12 v-if="contact.phone">
            <v-text-field
              readonly
              outlined
              label="Phone"
              dense
              v-model="contact.phone"
              class="textfd"
            ></v-text-field>
          </v-flex>
           <v-flex xs12 v-if="contact.interestedIn">
            <v-text-field
              readonly
              outlined
              label="Interested In"
              dense
              v-model="contact.interestedIn.name"
              class="textfd"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 v-if="contact.message">
            <v-textarea
              readonly
              outlined
              label="Message"
              class="textfd"
              v-model="contact.message"
            >
            </v-textarea>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      //   items: ["m1", "m2", "m3"],
      contact: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/contact/get",
        headers: {
          "token": localStorage.getItem("token"),
        },
        params:{
            id:this.$route.query.contact
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.contact = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>